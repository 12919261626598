import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Subnav from '../../components/subnav'
import Container from '../../components/container'
import { GatsbyImage } from 'gatsby-plugin-image'
import Covid19Footer from '../../components/covid19_footer'
import Link from '../../components/link'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import HeaderSpacer from '../../components/header_spacer'
import Testimonial from '../../components/testimonial'

const Covid19ResourcePage = ({ data }) => {

  const content = data.contentfulCovid19ResourcePage
  const resourcePages = data.allContentfulCovid19ResourceCategory.nodes
  const categoryUrl = content.covid_19_resource_category[0].url
  const isCaseStudy = content.items[0].internal?.type === 'ContentfulCovid19CaseStudy'
  
  let caseStudy
  if (isCaseStudy) {
    caseStudy = content.items[0]
  }

  return (
    <Layout activeNav={true}>
      <Seo
        title={content.seoTitle || content.title}
        description={content.metaDescription || content.blurb}
      />
      <HeaderSpacer dark />
      <Subnav
        links={[
          ...resourcePages.map(item => (
            {
              key: `subnav_${item.url}`,
              to: `/covid19/${item.url}`,
              text: item.title
            }
          ))
        ]}
      />
      <Container size='lg' className='mt-3'>
        <Link
          className='font-semi-bold uppercase p-1 -m-1'
          href={`/covid19/${categoryUrl}`}
          arrowDirection='left'
          arrowPosition='before'
          variant='muted'
          children='Back'
        />
        <div className='text-center mb-4 sm:mb-5'>
          <h2 className='text-xl sm:text-2xl'>
            {content.title}
          </h2>
          <div className='text-base sm:text-md mx-auto' style={{ maxWidth: '56em' }}>
            {renderRichText(content.text)}
          </div>
          {content.url &&
            <div className='mt-3'>
              <Link
                href={content.url}
                variant='muted'
                className='uppercase underline'
                children={`Visit ${content.title}`}
              />
            </div>
          }
        </div>
      </Container>

      {caseStudy && <>
        <div className='Section Section--stripe Section--condensed'>
          <Container>
            <div className='grid sm:grid-cols-2 sm:gap-x-3 items-center'>
              <div>
                <h3 className='text-xl sm:text-2xl font-light mt-0'>Challenge</h3>
                <div className='text-base sm:text-md leading-relaxed'>
                  {renderRichText(caseStudy.challengeText)}
                </div>
              </div>
              <div>
                {caseStudy.challengeImage &&
                  <GatsbyImage
                    image={caseStudy.challengeImage.gatsbyImageData}
                    alt=''
                  />
                }
              </div>
            </div>
          </Container>
        </div>
        <div className='Section Section--condensed'>
          <Container>
            <div className='grid sm:grid-cols-2 sm:gap-x-3 items-center'>
              <div>
                <h3 className='text-xl sm:text-2xl font-light mt-0'>Solution</h3>
                <div className='text-base sm:text-md leading-relaxed'>
                  {renderRichText(caseStudy.solutionText)}
                </div>
              </div>
              <div>
                {caseStudy.solutionImage &&
                  <GatsbyImage
                    image={caseStudy.solutionImage.gatsbyImageData}
                    alt=''
                  />
                }
              </div>
            </div>
          </Container>
        </div>
        {caseStudy.testimonial &&
          <Testimonial data={caseStudy.testimonial} />
        }
        {caseStudy.results &&
          <div className='Section'>
            <Container size='md'>
              <h3 className='text-xl sm:text-2xl font-light text-center mt-0 mb-4 md:mb-5'>Results</h3>
              <div className='grid gap-4 md:gap-5 sm:grid-cols-2'>
                {caseStudy.results.map(result =>
                  <div key={`case_study_result_${result.title}`}>
                    <h4 className='uppercase text-md md:text-lg font-semi-bold text-center tracking-wide'>
                      {result.title}
                    </h4>
                    <hr />
                    <div className='flex items-center'>
                      <GatsbyImage
                        image={result.image.gatsbyImageData}
                        alt=''
                        className='shrink-0'
                      />
                      <div className='ml-2 flex-1 leading-relaxed text-base sm:text-md'>
                        {result.blurb}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </div>
        }
      </>}
      
      {!caseStudy && content.items.map((item, index) => (
        <div className='Section Section--condensed pt-0' key={`resource_item_${item.title}`}>
          <Container size='lg'>
            <div className='grid items-center sm:grid-cols-2 sm:gap-x-5'>
              <div className='order-first sm:order-none'>
                <h3 className='text-xl sm:text-2xl font-regular m-0'>
                  {item.titleLink ?
                    <Link
                      href={item.titleLink}
                      variant='underline'
                      children={item.title}
                    /> : item.title}
                </h3>
                <div className='font-semi-bold text-gray mt-2 mb-3 leading-tight'>
                  {item.date}
                </div>
                <div className='Section-text'>
                  {renderRichText(item.text)}
                </div>
              </div>
              {item.image &&
                <div className={index % 2 === 1 ? 'order-first' : null}>
                  <GatsbyImage
                    image={item.image.gatsbyImageData}
                    alt=''
                  />
                </div>
              }
            </div>
          </Container>
        </div>
      ))}
      <Covid19Footer />
    </Layout>
  )
}

export default Covid19ResourcePage

export const pageQuery = graphql`
  query ($url: String) {
    contentfulCovid19ResourcePage(url: {eq: $url}) {
      items {
        ... on ContentfulCovid19ResourceItem {
          title
          titleLink
          date(formatString: "MM/DD/Y")
          text {
            raw
          }
          image {
            gatsbyImageData(placeholder: BLURRED, width: 531)
          }
        }
        ... on ContentfulCovid19CaseStudy {
          challengeImage {
            gatsbyImageData(placeholder: BLURRED, width: 636)
          }
          challengeText {
            raw
          }
          solutionImage {
            gatsbyImageData(placeholder: BLURRED, width: 636)
          }
          solutionText {
            raw
          }
          testimonial {
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
            quote {
              childMdx {
                body
              }
            }
            name
            title
          }
          results {
            blurb
            title
            image {
              gatsbyImageData(placeholder: BLURRED, width: 110)
            }
          }
          internal {
            type
          }
        }
      }
      title
      seoTitle
      metaDescription
      text {
        raw
      }
      covid_19_resource_category {
        url
      }
    }
    allContentfulCovid19ResourceCategory(sort: {fields: path_Id}) {
      nodes {
        title
        url
      }
    }
  }
`
